//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [
      { icon: "mdi-plus-circle-outline", title: "Afegir", action: "goedit" },
      { icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" },
    ],
    itemList: [],
  }),
  created() {},
  mounted() {
    this.getItemList();
  },
  methods: {
    goBack() {
      this.$router.push("/workcalendaredit");
    },
    goMenu(action) {
      switch (action) {
        case "gomenu":
      this.$router.push("/mainmenu");
          break;
        case "goedit":
          this.goEdit(0);
          break;
        default:
      }
    },
    getStyle(readOnly) {
      if (readOnly == "Y") {
        return "color: gray";
      } else {
        return "color: black";
      }
    },
    getItemList() {
      sessionStorage["sessDocLineId02"] = 0;
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio02_getitemsbyid", {
          token: sessionStorage.getItem("Token"),
          summary: false,
          docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
          docYear: parseInt(sessionStorage["sessDocYear"]),
          docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
          docId: parseInt(sessionStorage["sessDocId"]),
          docLineId: parseInt(sessionStorage["sessDocLineId"]),
          docLineId02: parseInt(sessionStorage["sessDocLineId02"]),
        })
        .then((response) => {
          this.itemList = response.data;
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    goEdit(docLineId02) {
      sessionStorage["sessDocLineId02"] = parseInt(docLineId02);
      this.$router.push("/workorder02edit");
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };
      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
